import React, { useContext } from "react";
import {
  AccordionSummary,
  makeStyles,
  Typography,
} from "../../libraries/material/core";
import PropTypes from "prop-types";
import { ExpandMore } from "@material-ui/icons";
import { TableOfContentsControllerContext } from "../context";

const useStyles = makeStyles(() => {
  return {
    anchorLink: ({ navHeight, expandable }) => {
      return {
        paddingBottom: expandable ? undefined : ".5em",
        scrollMarginTop: `${navHeight || 0}px` 
      };
    },
  };
});

const AnchorHeading = (props = {}) => {
  const {
    variant,
    tabIndex,
    className,
    children,
    expandable,
    accordionSummaryProps,
    ...rest
  } = props;
  const { navHeight } = useContext(TableOfContentsControllerContext)
  const classes = useStyles({ navHeight, expandable });

  let Wrapper = React.Fragment;
  if (expandable) {
    // eslint-disable-next-line react/display-name
    Wrapper = (wrapperProps = {}) => <AccordionWrapper 
      {...accordionSummaryProps}
      {...wrapperProps}
    />
    Wrapper.displayName = "AccordionWrapper"
  }
  
  return (
    <Wrapper>
      <Typography
        {...rest}
        variant={variant}
        tabIndex={tabIndex}
        className={`${classes.anchorLink} ${className}`.trim()}
      >
        {children}
      </Typography>
    </Wrapper>
  );
};

function AccordionWrapper(props = {}) {
  return (
    <AccordionSummary expandIcon={<ExpandMore />} {...props}></AccordionSummary>
  );
}

AnchorHeading.defaultProps = {
  className: "",
  variant: "h2",
  component: "h2",
  tabIndex: "-1",
  accordionSummaryProps: {}
};

AnchorHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  expandable: PropTypes.bool,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "srOnly",
    "inherit",
  ]),
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accordionSummaryProps: PropTypes.object
};

export default AnchorHeading;
