import React from "react";
import PropTypes from 'prop-types';
import useTableOfContents from "./useTableOfContents";
import { TableOfContentsControllerProvider, TableOfContentsPositioningProvider, TableOfContentsProvider } from "./context";

const withTableOfContents = (WrappedComponent) => {
  function _withTableOfContents(props = {}) {
    const { navHeight, width, ...rest } = props;
    const componentName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";
    const { paths, position = {}, dispatchPosition, exists, setPaths } = useTableOfContents({ navHeight });

    const toc = {
      standard: {
        width,
        paths,
        exists
      },
      position,
      controller: {
        dispatchPosition,
        setPaths,
        navHeight
      }
    }

    _withTableOfContents.displayName = `withTableOfContents(${componentName})`;
    _withTableOfContents.propTypes = {
      navHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }

    return (
        <TableOfContentsControllerProvider value={toc.controller}>
          <TableOfContentsProvider value={toc.standard}>
            <TableOfContentsPositioningProvider value={toc.position}>
              <WrappedComponent navHeight={navHeight} {...rest} />
            </TableOfContentsPositioningProvider>
          </TableOfContentsProvider>
        </TableOfContentsControllerProvider>
    );
  }
  return _withTableOfContents;
};

export default withTableOfContents;
