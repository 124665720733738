import React from "react";
import grayLogo from "../assets/osu-navbar-gray.svg";
import whiteLogo from "../assets/osu-navbar-white.svg";

function Wordmark(props = {}) {
  const { darkMode, className } = props;
  return (
    <div className={className} id="osu-navname-block">
        <img
          className="bux-osu-nav__osu-logo-img"
          src={darkMode ? whiteLogo : grayLogo}
          alt="The Ohio State University"
        />
    </div>
  );
}

export default Wordmark;
